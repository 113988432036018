(function() {
  var app = angular.module('priorWeb');

  app.controller('PaymentController', [
    'GoogleAnalytics', '$localStorage', '$location', '$q', '$rootScope', '$scope',
    '$translate', '$uibModal', '$window', 'ActionEnum', 'Alert', 'Card', 'CheckDeliveryAddressStatusEnum',
    'CompositeMenuItemViewModel', 'Currency', 'CurrentOrder', 'CurrentUser', 'EventEnum', 'FbPixelEventEnum', 'FbPixel',
    'MangopayPreauthErrorEnum', 'MenuItemType', 'MenuItemViewModel', 'MenuItemsRepository', 'OrderErrorCodeEnum',
    'OrderLocationEnum', 'OrderStatusEnum', 'PaymentErrorEnum', 'PaymentProviderEnum', 'RedsysResponseEnum',
    'RestaurantViewModel', 'RestaurantsRepository', 'RuntimeError', 'State', 'Timezone', 'User', 'WhenDayEnum',
    'moment', 'trackGAViews',
    /**
     * @param {GoogleAnalyticsWrapper} googleAnalytics
     * @param {string[]} trackGAViews
     * @param {ng.ui.bootstrap.IModalService} $uibModal
     * @param {import('moment')} moment
     */
    function (
      googleAnalytics, $localStorage, $location, $q, $rootScope, $scope, $translate, $uibModal,
      $window, ActionEnum, Alert, Card, CheckDeliveryAddressStatusEnum,  CompositeMenuItemViewModel, Currency,
      CurrentOrder, CurrentUser, EventEnum, FbPixelEventEnum, FbPixel, MangopayPreauthErrorEnum, MenuItemType,
      MenuItemViewModel, MenuItemsRepository, OrderErrorCodeEnum, OrderLocationEnum, OrderStatusEnum, PaymentErrorEnum,
      PaymentProviderEnum, RedsysResponseEnum, RestaurantViewModel, RestaurantsRepository, RuntimeError, State,
      Timezone, User, WhenDayEnum, moment, trackGAViews
    ) {
      var restaurantId,
          restaurant,
          pendingPreAuthId = State.get().currentOrder.pendingPreAuthId,
          returnPreAuthId = $location.search().preAuthorizationId,
          redsysCode = $location.search().redsysCode;

      $scope.$on(EventEnum.RUNNING_REQUESTS_COUNTER_UPDATED, function(__event__, runningRequests) {
        if (runningRequests === 0) {
          angular.element('#action button.btn-primary').removeClass('disabled').prop('disabled', false);
        } else {
          angular.element('#action button.btn-primary').addClass('disabled').prop('disabled', true);
        }
      });

      $scope.$on('prior.places-autocomplete-updated', function(event, addressComponents) {
        var zipCode = addressComponents.reduce(function(zip_code, component) {
          if (component.types.indexOf('postal_code') != -1) {
            return component.long_name;
          } else {
            return zip_code;
          }
        }, '');

        $scope.$apply(function() {
          $scope.formData.zipCode = zipCode;
          CurrentOrder.setZipCode(zipCode);
        });
      });

      $scope.orderDiscount = 0;
      $scope.orderTotal = 0;
      $scope.isDelivery = isDelivery;

      $scope.$on('$viewContentLoaded', function() {
        trackGAViews.forEach(function(propertyId) {
          googleAnalytics.trackView(propertyId, 'payment');
        });
        FbPixel.track(FbPixelEventEnum.INIT_CHECKOUT);
      });

      $scope.loadedEverything.then(function() {
        requestScheduleStatus();
        restaurantId = CurrentOrder.getRestaurantId();
        restaurant = RestaurantsRepository.get(restaurantId);

        if (!CurrentUser.isSet() || !restaurantId || !restaurant || !CurrentOrder.hasItems() ||
            CurrentOrder.getStatus() != OrderStatusEnum.CREATED) {
          $location.url('/');
          return;
        }

        Currency.set(restaurant.getCurrency());

        if (pendingPreAuthId) {
          if (!returnPreAuthId || pendingPreAuthId === returnPreAuthId) {
            CurrentOrder.confirmPreauth(pendingPreAuthId)
              .then(goToOrders)
              .catch(function(httpResponse) {
                var errorCode = httpResponse.data && httpResponse.data.code ? httpResponse.data.code.toString() : '0';

                showPaymentErrorAndRedirect(errorCode);
                CurrentOrder.cancelPreauth(pendingPreAuthId);
              });
          } else {
            CurrentOrder.cancelPreauth($location.search().preAuthorizationId);
            CurrentOrder.cancelPreauth(pendingPreAuthId);
            showPaymentErrorAndRedirect(MangopayPreauthErrorEnum.SECURE_MODE_CANCELED);
          }
        } else if (redsysCode) {
          showPaymentErrorAndRedirect(redsysCode);
        }

        refreshUserInfo();

        $scope.restaurantView = RestaurantViewModel.build(restaurant);
        $scope.restaurantOpeningHours = $scope.restaurantView.getOpeningHours() ||
          $translate.instant('NAVBAR.RESTAURANT_CLOSED_LABEL');
        $scope.restaurantDeliveryCharge = restaurant.getDeliveryCharge();
        $scope.deliveryCharge = CurrentOrder.getDeliveryCharge();
        $scope.$watch(
          function () {
            return CurrentUser.get().getName();
          },
          function(newName, oldName) {
            if (newName !== oldName) {
              $scope.formData.name = newName;
            }
          }
        );
        $scope.$watch(
          function watchDeliveryChangeValue(scope) {
            return scope.deliveryCharge.value;
          },
          applyPromosAndUpdateOrderTotal
        );

        if (CurrentOrder.getPromoCode()) {
          checkPromoCode();
        } else {
          applyPromosAndUpdateOrderTotal();
        }

        $scope.cardNumber = CurrentUser.get().getCard() ? CurrentUser.get().getCard().getNumber().substring(12) : null;

        $scope.addCard = function(alertText) {
          var modalScope = $rootScope.$new(),
              openModal = function() {
                var modal = $uibModal.open({
                  animation: true,
                  templateUrl: 'templates/card-modal.html',
                  controller: 'CardController',
                  scope: modalScope,
                  keyboard: false,
                  backdrop: 'static'
                });

                modal.result.then(function(newCard) {
                  var user = CurrentUser.get();
                  user.setCard(newCard);
                  CurrentUser.set(user);
                  $scope.cardNumber = newCard.getNumber();
                }, function() {
                  // Closed
                });
              };

          modalScope['paymentType'] = CurrentOrder.getPaymentType();

          if (alertText !== undefined) {
            Alert.show(alertText).result.finally(function() {
              openModal();
            });
          } else {
            openModal();
          }
        };

        $scope.clearPromoCode = clearPromoCode;

        $scope.deleteCard = function() {
          var cardId = CurrentUser.get().getCard().getId();
          Card.delete({ id :cardId }).$promise.then(function() {
            refreshUserInfo();
            Alert.show($translate.instant('PAYMENT_PAGE.CARD_DELETED_MESSAGE'));
          }).catch(function() {
            Alert.show($translate.instant('PAYMENT_PAGE.CARD_COULD_NOT_BE_DELETED_MESSAGE'));
          });
        }

        $scope.getView = function(menuItem) {
          var view;

          switch (menuItem.getType()) {
            case MenuItemType.STANDALONE:
            case MenuItemType.SPECIFIC:
            case MenuItemType.COMPOSITE:
              view = 'templates/order-menu-item.html';
              break;
            case MenuItemType.CUSTOMIZED:
              view = 'templates/composite-order-menu-item.html';
              break;
            default:
              throw RuntimeError.build({
                message: 'Unknown or unsupported menu item type "' + menuItem.getType() + '"'
              });
          }

          return view;
        };

        $scope.getViewItem = function(menuItem) {
          var viewItem;

          switch (menuItem.getType()) {
            case MenuItemType.CUSTOMIZED:
              viewItem = CompositeMenuItemViewModel.build(menuItem);
              break;
            case MenuItemType.SPECIFIC:
            case MenuItemType.STANDALONE:
            case MenuItemType.COMPOSITE:
              viewItem = MenuItemViewModel.build(menuItem);
              break;
            default:
              throw RuntimeError.build({
                message: 'Unknown or unsupported menu item type "' + menuItem.getType + '"'
              });
          }
          var quantity = CurrentOrder.getItemQuantity(menuItem.getId());
          viewItem.setQuantity(quantity);
          viewItem.setIsSelected(quantity > 0);

          return viewItem;
        };

        $scope.items = CurrentOrder.getAll().reduce(function(items, orderItem) {
          items.push(MenuItemsRepository.get(orderItem.getMenuItemId()));

          return items;
        }, []);

        $scope.saveOrder = function() {
          $scope.paymentFormClass = 'ng-submitted';
          CurrentOrder.setComments($scope.formData.comments);
          CurrentOrder.setPhone($scope.formData.phone);

          if (isDelivery()) {
            CurrentOrder
              .setDeliveryAddress($scope.formData.address)
              .then(function() {
                return CurrentOrder.setZipCode($scope.formData.zipCode);
              })
              .then(applyPromosAndUpdateOrderTotal);
          }

          validate().then(function() {
            if (CurrentOrder.getStatus() === OrderStatusEnum.CREATED) {
              reviewOrder();
            } else {
              goToOrders();
            }
          })
            .catch(function(error) {
              switch (error) {
                case 'invalidDeliveryAddress':
                  Alert.show($translate.instant('PAYMENT_PAGE.DELIVERY_ADDRESS_INVALID'));
                  break;
                case 'invalidForm':
                  var firstError;
                  for (var key in $scope.paymentForm.$error) {
                    firstError = $scope.paymentForm.$error[key][0].$name;
                    break;
                  }
                  angular.element('[name="' + firstError + '"]').focus();
                  break;
                case 'invalidName':
                  Alert.show($translate.instant('PAYMENT_PAGE.INVALID_NAME_MESSAGE'));
                  break;
                case 'invalidTotal':
                  var minimumOrder = restaurant.getMinimumOrder(CurrentOrder.getWhere()),
                      difference = minimumOrder - (CurrentOrder.getItemsTotal());

                  Alert.show(
                    $translate.instant('PAYMENT_PAGE.MINIMUM_ORDER_NOT_MET_MESSAGE', {
                      minimumOrder: Currency.format(minimumOrder),
                      difference: Currency.format(difference)
                    })
                  );
                  break;
                case 'missingCard':
                  var text = $translate.instant('PAYMENT_PAGE.MISSING_CARD_MESSAGE', {
                    hasCredit: $scope.credit > 0
                  }, 'messageformat');
                  $scope.addCard(text);
                  break;
                case 'missingPaymentType':
                  showPaymentTypeError(CurrentOrder.getPaymentType());
                  break;
                case 'orderLimitReached':
                  Alert.show($translate.instant('PAYMENT_PAGE.ORDER_LIMIT_REACHED'));
                  break;
                case 'notCoveredDeliveryAddress':
                  Alert.show($translate.instant('PAYMENT_PAGE.NOT_COVERED_DELIVERY_ADDRESS_ERROR_MODAL_MESSAGE'));
              }
            });
        };

        $scope.savePromoCode = savePromoCode;

        $scope.updateComments = function() {
          CurrentOrder.setComments($scope.formData.comments);
        };

        $scope.updateDeliveryAddress = function() {
          CurrentOrder.setDeliveryAddress($scope.formData.address);
          applyPromosAndUpdateOrderTotal();
        };

        $scope.updateName = function() {
          var user = CurrentUser.get();
          user.setName($scope.formData.name);
          CurrentUser.set(user);
        }

        $scope.updateOrderWhen = function() {
          CurrentOrder.setWhen($scope.formData.when ? $scope.formData.when.value : null);
          requestScheduleStatus();
        };

        $scope.updateOrderWhenDay = function() {
          $scope.formData.when = null;
          $scope.updateOrderWhen();
          $scope.whenOptions = getPickUpTimes(
            restaurant.getMinimumPrepTime(CurrentOrder.getWhere()),
            restaurant.hasAsapOrder()
          );
        };

        $scope.updateOrderWhere = function() {
          CurrentOrder.setWhere($scope.formData.where.value);
          if (CurrentOrder.getWhere() !== OrderLocationEnum.DELIVERY) {
            CurrentOrder.setDeliveryAddress(undefined);
            CurrentOrder.setZipCode(undefined);
          } else {
            CurrentOrder.setDeliveryAddress($scope.formData.address);
            CurrentOrder.setPhone($scope.formData.phone);
            CurrentOrder.setZipCode($scope.formData.zipCode);
          }

          $scope.formData.when = null;
          $scope.formData.whenDay = {
            value: WhenDayEnum.TODAY
          };
          $scope.updateOrderWhen();
          $scope.whenOptions = getPickUpTimes(
            restaurant.getMinimumPrepTime(CurrentOrder.getWhere()),
            restaurant.hasAsapOrder()
          );

          applyPromosAndUpdateOrderTotal();
        };

        $scope.updatePaymentType = function(paymentType) {
          CurrentOrder.setPaymentType(paymentType);
          refreshUserInfo();
        }

        $scope.updatePhone = function() {
          CurrentOrder.setPhone($scope.formData.phone);
        };

        $scope.updateZipCode = function() {
          CurrentOrder.setZipCode($scope.formData.zipCode);
          applyPromosAndUpdateOrderTotal();
        };

        $scope.formData = {
          whenDay: {
            value: isToday(CurrentOrder.getWhen()) ?
              WhenDayEnum.TODAY : WhenDayEnum.TOMORROW
          },
          address: CurrentOrder.getDeliveryAddress(),
          name: CurrentUser.get().getName(),
          phone: CurrentOrder.getPhone(),
          zipCode: CurrentOrder.getZipCode(),
          comments: CurrentOrder.getComments()
        };

        if (restaurant.hasNextDayOrder() && restaurant.isOpenTomorrow()) {
          $scope.whenDayOptions = [
            {
              name: $translate.instant('PAYMENT_PAGE.TODAY_LABEL'),
              value: WhenDayEnum.TODAY
            },
            {
              name: $translate.instant('PAYMENT_PAGE.TOMORROW_LABEL'),
              value: WhenDayEnum.TOMORROW
            }];
        }

        $scope.whenOptions = getPickUpTimes(
          restaurant.getMinimumPrepTime(CurrentOrder.getWhere()),
          restaurant.hasAsapOrder()
        );

        $scope.whereOptions = restaurant.getWhereOptions();
        if ($scope.whereOptions.length === 0) {
          $scope.whereOptions = [
            {
              name: 'PAYMENT_PAGE.TAKEOUT_LABEL',
              value: OrderLocationEnum.TAKEOUT
            },
            {
              name: 'PAYMENT_PAGE.DINING_IN_LABEL',
              value: OrderLocationEnum.DINING_IN
            }
          ];
        }

        if (isValidWhen()) {
          $scope.formData['when'] = { value: CurrentOrder.getWhen() };
        } else {
          $scope.formData['when'] = null;
          $scope.updateOrderWhen();
        }

        if ($scope.whereOptions.map(function(option) {
          return option.value;
        }).indexOf(CurrentOrder.getWhere()) == -1) {
          CurrentOrder.setWhere(restaurant.getDefaultOrderType());
        }

        $scope.formData['where'] = { value: CurrentOrder.getWhere() };

        $scope.promoCodeFormData = {
          promoCode: ''
        };
      });

      function applyPromosAndUpdateOrderTotal() {
        updateOrderTotal();
        CurrentOrder.applyPromos().finally(function() {
          updateOrderTotal();
        });
      }

      /**
       * @param {import('moment').Moment} time
       * @param {number[]} bitsArray
       */
      function checkInterval(time, bitsArray) {
        var utcTime = moment.tz(time, moment.tz.guess()).utc();
        var interval = utcTime.hour();
        var intervalIndex = Math.floor(utcTime.minute() / 15);

        return bitsArray[intervalIndex] >>> interval & 1;
      }

      function checkPromoCode() {
        function handleInvalidCode() {
          Alert.show($translate.instant('PAYMENT_PAGE.INVALID_COUPON_ERROR'));
          clearPromoCode();
        }

        CurrentOrder.checkPromoCode()
          .then(function checkPromoCodeSuccess(validCode) {
            if (validCode) {
              $scope.promoCode = CurrentOrder.getPromoCode();
              applyPromosAndUpdateOrderTotal();
            } else {
              handleInvalidCode();
            }
          })
          .catch(function checkPromoCodeFailure() {
            handleInvalidCode();
          });
      }

      function clearPromoCode() {
        CurrentOrder.setPromoCode('');
        $scope.promoCodeFormData.promoCode= '';
        $scope.promoCode = '';
        applyPromosAndUpdateOrderTotal();
      }

      /**
       * @param {import('moment').Moment} time
       */
      function getNextQuarterHour(time) {
        const nextQuarterTime = time.clone();
        const minutes = nextQuarterTime.minutes();

        // Round up to nearest quarter hour
        nextQuarterTime.minutes(15 * Math.ceil(minutes / 15));
        nextQuarterTime.startOf('minute');

        return nextQuarterTime;
      }

      /**
       * @return {import('moment').Moment}
       */
      function getAsapTime() {
        var time = moment();
        time.add(restaurant.getMinimumPrepTime(CurrentOrder.getWhere()), 'minute');

        return time;
      }

      function getPickUpTimes(prepTime, hasAsapOrder) {
        var date = moment();
        var closingTimes;
        var nextTimeInterval;
        var openingTimes;
        var times = [];

        if ($scope.formData.whenDay.value == WhenDayEnum.TOMORROW) {
          date.add(1, 'day');
          date.startOf('date');
        } else if (hasAsapOrder && restaurant.isOpenNow()) {
          var asapAvailable = !$scope.scheduleOrdersLimits || checkInterval(getAsapTime(), $scope.scheduleOrdersLimits);
          var suffix = asapAvailable ? '' : ' - ' + $translate.instant('PAYMENT_PAGE.UNAVAILABLE_LABEL');

          times.push({
            name: $translate.instant('PAYMENT_PAGE.ASAP_OPTION_LABEL') + suffix,
            value: -1,
            disabled: !asapAvailable
          });
        }

        nextTimeInterval = getNextQuarterHour(date.clone().add(prepTime, 'minutes'));
        openingTimes = restaurant.getOpeningTimesOnDay(date);
        closingTimes = restaurant.getClosingTimesOnDay(date);
        if (openingTimes.length > 0) {
          times = openingTimes.reduce(function(times, openingTime, index) {
            if (moment(closingTimes[index]).isAfter(date)) {
              var firstTime = moment.max(moment(openingTime), nextTimeInterval),
                  lastTime = moment(closingTimes[index]);

              for (
                var currentTime = firstTime.clone();
                currentTime.isSameOrBefore(lastTime);
                currentTime.add(15, 'minute')
              ) {
                var time = currentTime.format('HH:mm');
                var isIntervalAvailable = $scope.scheduleOrdersLimits ?
                    checkInterval(currentTime, $scope.scheduleOrdersLimits) :
                    true;
                var suffix = isIntervalAvailable ? '' : ' - ' + $translate.instant('PAYMENT_PAGE.UNAVAILABLE_LABEL');
                times.push({
                  name: time + suffix,
                  disabled: !isIntervalAvailable,
                  value: currentTime.format('YYYY-MM-DD HH:mm:ss')
                });
              }
            }

            return times;
          }, times);
        }

        return times;
      }

      function goToOrders() {
        var orderId = CurrentOrder.getId();
        CurrentOrder.clear();
        $location.url('/ordered/' + restaurant.getId() + '/' + orderId);
        return;
      }

      function handleMangoPayPreauthError(httpResponse) {
        var errorCode = httpResponse.data && httpResponse.data.code ? httpResponse.data.code.toString() : '0';

        if (errorCode === MangopayPreauthErrorEnum.SECURE_MODE_REQUIRED) {
          State.dispatch({
            type: ActionEnum.REQUEST_3DS_AUTHORIZATION,
            preAuthId: httpResponse.data.preAuthId
          });
          $localStorage.state = State.get();
          $window.location = httpResponse.data.url;
        } else {
          showPaymentError(errorCode);
        }
      }

      function isToday(when) {
        return !when || when == -1 || moment(when).isSame(moment(), 'day');
      }

      function isValidWhen() {
        return CurrentOrder.getWhen() == -1 || (
          moment(CurrentOrder.getWhen()).isAfter(moment()) &&
            getPickUpTimes(
              restaurant.getMinimumPrepTime(CurrentOrder.getWhere()),
              restaurant.hasAsapOrder()
            ).map(function(time) {
          return time.value;
            }).indexOf(CurrentOrder.getWhen()) != -1
        );
      }

      function requestScheduleStatus() {
        console.log(CurrentOrder.getWhen());
        CurrentOrder.checkScheduleLimits(
          $scope.formData ? $scope.formData.whenDay.value == WhenDayEnum.TOMORROW : false
        ).then(function(response) {
          $scope.scheduleOrdersLimits = response;
          $scope.whenOptions = getPickUpTimes(
            restaurant.getMinimumPrepTime(CurrentOrder.getWhere()),
            restaurant.hasAsapOrder()
          );
        });
      }

      function reviewOrder() {
        var modal = $uibModal.open({
          animation: true,
          templateUrl: 'templates/review-order-modal.html',
          controller: 'ReviewOrderModalController',
          scope: $rootScope.$new(),
          keyboard: false,
          backdrop: 'static',
          size:'md'
        });

        modal.result.then(function(result) {
          saveOrder();
        }, function() {
          if (isDelivery() && $scope.formData.comments && $scope.formData.comments.indexOf('\t\n') > -1) {
            var COMMENTS_REGEX = /^([^\t\n]*)(\t\n)?(.*)$/s;
            $scope.formData.address += ', ' + $scope.formData.comments.replace(COMMENTS_REGEX, '$1');
            $scope.formData.comments = $scope.formData.comments.replace(COMMENTS_REGEX, '$3');
            CurrentOrder.setComments($scope.formData.comments);
            CurrentOrder
              .setDeliveryAddress($scope.formData.address)
              .then(applyPromosAndUpdateOrderTotal);
          }
        });
      }

      function showPaymentErrorAndRedirect(errorCode) {
        State.dispatch({
          type: ActionEnum.CLEAR_3DS_AUTHORIZATION
        });
        showPaymentError(errorCode).finally(function() {
          $location.url('/payment');
        });
      }

      function showPaymentError(errorCode) {
        var messageId;

        switch (errorCode) {
          case MangopayPreauthErrorEnum.TRANSACTION_REFUSED:
          case MangopayPreauthErrorEnum.TRANSACTION_REFUSED_AMOUNT_LIMIT:
          case MangopayPreauthErrorEnum.TRANSACTION_REFUSED_BY_TERMINAL:
          case MangopayPreauthErrorEnum.TRANSACTION_REFUSED_CARD_LIMIT_REACHED:
          case MangopayPreauthErrorEnum.TRANSACTION_REFUSED_DO_NOT_HONOUR:
          case MangopayPreauthErrorEnum.MAXIMUM_AMOUNT_EXCEEDED:
          case RedsysResponseEnum.DENIED_BY_ISSUER:
            messageId = 'PAYMENT_PAGE.TRANSACTION_DENIED_CREDIT_CARD_ERROR';
            break;
          case MangopayPreauthErrorEnum.CARD_EXPIRED:
          case RedsysResponseEnum.EXPIRED_CARD:
            messageId = 'PAYMENT_PAGE.EXPIRED_CREDIT_CARD_ERROR';
            break;
          case MangopayPreauthErrorEnum.CARD_INACTIVE:
          case MangopayPreauthErrorEnum.CARD_NOT_ACTIVE:
            messageId = 'PAYMENT_PAGE.INACTIVE_CREDIT_CARD_ERROR';
            break;
          case MangopayPreauthErrorEnum.SECURE_MODE_CANCELED:
          case MangopayPreauthErrorEnum.SECURE_MODE_NOT_AVAILABLE:
          case MangopayPreauthErrorEnum.SECURE_MODE_EXPIRED:
          case MangopayPreauthErrorEnum.SECURE_MODE_INCOMPATIBLE_CARD:
          case MangopayPreauthErrorEnum.SECURE_MODE_UNENROLLED_CARD:
          case MangopayPreauthErrorEnum.SECURE_MODE_FAILED:
            messageId = 'PAYMENT_PAGE.3DS_CREDIT_CARD_ERROR';
            break;
          case RedsysResponseEnum.WRONG_CVV2_CVC2:
            messageId = 'PAYMENT_PAGE.REDSYS_WRONG_CVV2_CVC2_ERROR_MESSAGE';
            break;
          case RedsysResponseEnum.WRONG_EXPIRY_DATE:
            messageId = 'PAYMENT_PAGE.REDSYS_WRONG_EXPIRY_DATE_ERROR_MESSAGE';
            break;
          default:
            messageId = 'PAYMENT_PAGE.PREAUTH_GENERIC_ERROR_MESSAGE';
            break;
        }

        refreshUserInfo();
        angular.element('#action button.btn-primary').addClass('disabled').prop('disabled', true);
        return Alert.show($translate.instant(messageId)).result.finally(function() {
          angular.element('#action button.btn-primary').removeClass('disabled').prop('disabled', false);
        });
      }

      function handleSaveError(httpResponse) {
        var messageId = 'PAYMENT_PAGE.GENERIC_SAVE_ORDER_ERROR',
            params = {};
        if (httpResponse.data && httpResponse.data.code) {
          switch (httpResponse.data.code) {
            case OrderErrorCodeEnum.ORDERING_NOT_ALLOWED:
              messageId = 'PAYMENT_PAGE.RESTAURANT_CLOSED_LABEL';
              params['nextStartTime'] = restaurant.getNextStartTime();
              params['openToday'] = !!restaurant.getNextStartTime();
              params['use_messageformat'] = true;
              break;
            case OrderErrorCodeEnum.DELIVERY_NOT_AVAILABLE:
              messageId = 'PAYMENT_PAGE.DELIVERY_UNAVAILABLE_LABEL';
              params['use_messageformat'] = true;
              break;
            default:
              messageId = httpResponse.data.messageId ? httpResponse.data.messageId : messageId;
              break;
          }
        }

        angular.element('#action button.btn-primary').addClass('disabled').prop('disabled', true);
        Alert.show($translate.instant(messageId, params, params.use_messageformat ? 'messageformat' : undefined)).result.finally(function() {
          angular.element('#action button.btn-primary').removeClass('disabled').prop('disabled', false);
        });
      }

      function isDelivery() {
        return parseInt(CurrentOrder.getWhere()) === OrderLocationEnum.DELIVERY;
      }

      function isKushkiOrder() {
        return CurrentOrder.getPaymentType() === PaymentProviderEnum.KUSHKI;
      }

      function isMangoPayOrder() {
        return CurrentOrder.getPaymentType() === PaymentProviderEnum.MANGOPAY;
      }

      function isPrePayOrder() {
        return [
          PaymentProviderEnum.CASH,
          PaymentProviderEnum.RAPPIPAY,
          PaymentProviderEnum.TPV
        ].indexOf(CurrentOrder.getPaymentType()) === -1;
      }

      function loadCreditCardAndCreditInfo() {
        if (isKushkiOrder() || isMangoPayOrder()) {
          $scope.cardNumber = CurrentUser.get().getCard() ? CurrentUser.get().getCard().getNumber().substring(12) : null;

          if (isMangoPayOrder()) {
            $scope.credit = Math.min(CurrentUser.get().getCredit(), CurrentOrder.getTotal());
            $scope.creditLeft = Math.max(CurrentUser.get().getCredit() - CurrentOrder.getTotal(), 0);
          }
        }
        applyPromosAndUpdateOrderTotal();
      }

      function preauthOrder() {
        switch(CurrentOrder.getPaymentType()) {
          case PaymentProviderEnum.KUSHKI:
            CurrentOrder.preauth().then(goToOrders).catch(showPaymentError);
            break;
          case PaymentProviderEnum.MANGOPAY:
            CurrentOrder.preauth().then(goToOrders).catch(handleMangoPayPreauthError);
            break;
          case PaymentProviderEnum.REDSYS:
            CurrentOrder.prepareRedsysAuthorization()
              .then(function(authorizationData) {
                var form = angular.element('<form action="' + authorizationData.getUrl() + '" method="POST">\
                      <input type="hidden" name="Ds_SignatureVersion" value="' + authorizationData.getSignatureType() + '"/>\
                      <input type="hidden" name="Ds_MerchantParameters" value="' + authorizationData.getTransactionData() + '"/>\
                      <input type="hidden" name="Ds_Signature" value="' + authorizationData.getSignature() + '"/>\
                  </form>');
                angular.element('body').append(form);
                form.submit();
              });
            break;
          default:
            showPaymentTypeError(CurrentOrder.getPaymentType());
          break;
        }
      }

      function showPaymentTypeError(paymentType) {
        if (typeof paymentType === undefined) {
          console.log('Missing Payment Type');
          showPaymentError(PaymentErrorEnum.MISSING_PAYMENT_TYPE);
        } else {
          console.log(paymentType + ' is not a valid payment type');
          showPaymentError(PaymentErrorEnum.INCORRECT_PAYMENT_TYPE);
        }
      }

      function refreshUserInfo() {
        User.get(
          {
            id: CurrentUser.get().getId(),
            currency: restaurant.getCurrency(),
            payment_provider: CurrentOrder.getPaymentType()
          },
          {},
          function(user) {
            user.setName(CurrentUser.get().getName());
            CurrentUser.set(user);
            loadCreditCardAndCreditInfo();
          }
        );
      }

      function saveName()
      {
        var user = CurrentUser.get();
        return User.saveName({
          id: user.getId(),
          name: $scope.formData.name
        }).$promise
            .then(function(result) {
            return result.success;
        });
      }

      function saveOrder() {
        return CurrentOrder.saveOrder().then(
          function() {
            if (
              CurrentUser.get().isVerified() && CurrentOrder.getTotal() > 0 &&
                isPrePayOrder() && CurrentOrder.getStatus() === OrderStatusEnum.CREATED
            ) {
              preauthOrder();
            } else {
              goToOrders();
            }
          }, handleSaveError);
      }

      function savePromoCode() {
        CurrentOrder.setPromoCode($scope.promoCodeFormData.promoCode);
        checkPromoCode();
      }

      function updateOrderTotal() {
        $scope.restaurantDeliveryCharge = restaurant.getDeliveryCharge();
        $scope.deliveryCharge = CurrentOrder.getDeliveryCharge();
        $scope.orderDiscount = CurrentOrder.getDiscount() + CurrentOrder.getItemsDiscount();
        $scope.orderTotal = CurrentOrder.getTotal() - CurrentUser.get().getCredit();
      }

      function validate() {
        return validateWhen()
          .then(validateForm)
          .then(validateName)
          .then(validateAddress)
          .then(validateCreditCard)
          .then(validateMinimumOrder)
          .then(validateScheduleStatus);
      }

      function validateCreditCard() {
        switch(CurrentOrder.getPaymentType()) {
          case PaymentProviderEnum.KUSHKI:
          case PaymentProviderEnum.MANGOPAY:
            if (CurrentOrder.getTotal() > CurrentUser.get().getCredit() && !$scope.cardNumber) {
              return $q.reject('missingCard');
            }
          case PaymentProviderEnum.CASH:
          case PaymentProviderEnum.RAPPIPAY:
          case PaymentProviderEnum.REDSYS:
          case PaymentProviderEnum.TPV:
            return $q.resolve(true);
          default:
            showPaymentTypeError(CurrentOrder.getPaymentType());
        }
      }

      function validateDeliveryAddress() {
        if (isDelivery()) {
          return CurrentOrder.checkDeliveryAddress().then(function(result) {
            if (result.status === CheckDeliveryAddressStatusEnum.OK && result.predictions.length === 1) {
              var deliveryAddress = result.predictions[0].terms
                  .filter(function(term) {
                    return term.value !== CurrentOrder.getCity() &&
                      term.value !== CurrentOrder.getZipCode() &&
                      term.value !== 'España' &&
                      term.value !== 'Spain';
                  })
                  .map(function(term) {
                    return term.value;
                  })
                  .join(', ');
              var addressUpdated = CurrentOrder.setDeliveryAddress(deliveryAddress);
              $scope.formData.address = deliveryAddress;

              if (result.comment) {
                var currentComments = CurrentOrder.getComments(),
                    newComments = result.comment + '\t\n' + (currentComments ? currentComments : '');
                CurrentOrder.setComments(newComments);
                $scope.formData.comments = newComments;
              }

              return addressUpdated.then(function() {
                return true;
              });
            } else {
              return $q.reject(result.status);
            }
          }).catch(function(status) {
            if (status == CheckDeliveryAddressStatusEnum.NOT_COVERED) {
              return $q.reject('notCoveredDeliveryAddress');
            } else {
              return $q.reject('invalidDeliveryAddress');
            }
          });
        } else {
          $q.resolve(true);
        }
      }

      function validateAddress() {
        if (restaurant.getAddressValidation() && isDelivery()) {
          return validateDeliveryAddress();
        } else {
          return $q.resolve(true);
        }
      }

      function validateForm() {
        if (!$scope.paymentForm.$valid) {
          return $q.reject('invalidForm');
        } else {
          return $q.resolve(true);
        }
      }

      function validateMinimumOrder() {
        if (CurrentOrder.getItemsTotal() > 0 &&
            CurrentOrder.getItemsTotal() <
            restaurant.getMinimumOrder(CurrentOrder.getWhere())
        ) {
          return $q.reject('invalidTotal');
        } else {
          return $q.resolve(true);
        }
      }

      function validateName() {
        return saveName().then(function(success) {
          if (success) {
            return true;
          } else {
            return $q.reject('invalidName');
          }
        });
      }

      function validateScheduleStatus() {
        return CurrentOrder.checkScheduleLimits(
          $scope.formData.whenDay.value == WhenDayEnum.TOMORROW
        ).then(function(result) {
          $scope.scheduleOrdersLimits = result;
          if (
            checkInterval(
              CurrentOrder.getWhen() === -1 ? getAsapTime() : moment(CurrentOrder.getWhen()),
              $scope.scheduleOrdersLimits
            )
          ) {
            return $q.resolve(true);
          } else {
            $scope.whenOptions = getPickUpTimes(
              restaurant.getMinimumPrepTime(CurrentOrder.getWhere()),
              restaurant.hasAsapOrder()
            );

            return $q.reject('orderLimitReached');
          }
        }).catch(function(err) {
          console.log('error', err);
          return $q.reject('orderLimitReached');
        });
      }

      function validateWhen() {
        if (isValidWhen()) {
          return $q.resolve(true);
        } else {
          CurrentOrder.setWhen(null);
          $scope.formData.when = null;
          $scope.whenOptions = getPickUpTimes(
            restaurant.getMinimumPrepTime(CurrentOrder.getWhere()),
            restaurant.hasAsapOrder()
          );

          return $q.reject('invalidForm');
        }
      }
    }
  ]);
})();
